import {
  effect,
	untracked,
  type EffectRef,
	type CreateEffectOptions,
	type EffectCleanupRegisterFn
} from '@angular/core';

type ExplicitEffectDeps<T> = {
	[K in keyof T]: () => T[K];
};

/** Extends options passed to the `effect`. */
interface CreateExplicitEffectOptions extends CreateEffectOptions {
	/** Skips initial effect execution - only run on first change. */
	readonly defer?: boolean;
}

/**
 * A function that takes the deps and the side-effect function to run when the deps change.
 * The side-effect function runs as untracked to prevent any unnecessary effect executions.
 *
 * @example
 * ```typescript
 * import { explicitEffect } from '@vermeer-corp/it-ng-components/core';
 *
 * const count = signal(0);
 * const state = signal('init');
 *
 * explicitEffect(
 *   [count, state],
 *   ([count, state], cleanup) => {
 *     console.log('count updated', count, state);
 *     cleanup(() => console.log('cleanup'));
 *   },
 *   { defer: true }
 * );
 * ```
 *
 * @param deps The dependencies that trigger the effect (signals, computed signals, functions that contain signals)
 * @param fn The function to run when the dependencies change
 * @param options `CreateExplicitEffectOptions` effect options with the addition of defer (instructs effect to run on first change, not immediately)
 */
export function explicitEffect<T extends readonly unknown[], Params = T>(
	deps: readonly [...ExplicitEffectDeps<T>],
	fn: (deps: Params, onCleanup: EffectCleanupRegisterFn) => void,
	options?: CreateExplicitEffectOptions,
): EffectRef {
	let defer = options?.defer ?? false;

	return effect((onCleanup) => {
		const depValues = deps.map((s) => s());

		untracked(() => {
			if (!defer) {
				fn(depValues as Params, onCleanup);
			} else {
			  defer = false;
      }
		});
	}, options);
}