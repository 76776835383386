/* eslint-disable @angular-eslint/component-selector */
import {
  inject,
  Input,
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import { VMR_GLOBAL_CONFIG } from '../providers';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import type { IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * A wrapper component used to dynamically select the parent fontawesome tag (<fa-icon> | <i>).
 * Fleet is not using angular fontawesome and renders icons using <i> tags.
 */
@Component({
  standalone: true,
  selector: 'fa-icon-wrapper',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (!_useIconTag) {
      <fa-icon [class]="iconClass" [icon]="icon" />
    } @else {
      <i [class]="legacyClass"></i>
    }
  `,
  imports: [FaIconComponent],
})
export class FaIconWrapperComponent {
  protected _useIconTag = inject(VMR_GLOBAL_CONFIG).useIconTag;

  @Input() iconClass?: string;
  @Input() iconPrefix: IconPrefix = 'fas';
  @Input({ required: true }) icon!: IconProp;

  get legacyClass(): string {
    return `${this.iconPrefix} fa-${this.icon} ${this.iconClass || ''}`.trim();
  }
}