import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Input, Output, booleanAttribute, inject, OnDestroy, NgZone } from '@angular/core';
import { Subscription, auditTime, fromEvent } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[vmrClickOutside]'
})
export class VmrClickOutside implements OnDestroy {
  private _clickSubscription$?: Subscription;
  private readonly _ngZone = inject(NgZone);
  private readonly _document = inject(DOCUMENT);
  private readonly _elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  /** Controls creation/destruction of click event subscription. */
  @Input({
    transform: booleanAttribute,
    alias: 'vmrClickOutsideActive'
  })
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    if ((this._active = value)) {
      this._initClickSubscription();
    } else {
      this._destroyClickSubscription();
    }
  }
  private _active: boolean = false;

  /** IDs of descendant nodes to exempt from click events. */
  @Input('vmrClickOutsideIgnore') ignoredElementsIds?: string[];
  @Output('vmrClickOutside') readonly clickOutside = new EventEmitter<void>();

  ngOnDestroy(): void {
    this._destroyClickSubscription();
  }

  private _destroyClickSubscription(): void {
    this._clickSubscription$?.unsubscribe();
  }

  private _clickOriginIsIgnoredElement(e: PointerEvent): boolean {
    if (!this.ignoredElementsIds?.length) {
      return false;
    }

    return this.ignoredElementsIds.some((id: string) => {
      const el = this._document.getElementById(id);
      return el?.contains(e.target as Node);
    });
  }

  private _initClickSubscription(): void {
    const clickOutside$ = fromEvent<PointerEvent>(this._document, 'click').pipe(auditTime(20));

    this._ngZone.runOutsideAngular(() => {
      this._clickSubscription$ = clickOutside$.subscribe((e) => {
        if (
          !this._elementRef.nativeElement.contains(e.target as Node) &&
          !this._clickOriginIsIgnoredElement(e)
        ) {
          this._ngZone.run(() => this.clickOutside.emit());
        }
      });
    });
  }
}