import { effect, signal, type Signal } from '@angular/core';
import { getDefaultWindow, hasMatchMediaSupport } from '../helpers';
import { toValue } from './internals';

/**
 * Reactive Media Query.
 *
 * @param query The media query to match - e.g. (max-width: 991px).
 * @param window The window instance to bind event listeners to.
 */
export function observeMediaQuery(
  query: string | Signal<string> | (() => string),
  window: (Window & typeof globalThis) | undefined = getDefaultWindow()
): Signal<boolean> {
  const matches = signal(false);
  const matchMediaHandler = (event: MediaQueryListEvent) => matches.set(event.matches);

  let mediaQuery: MediaQueryList;

  effect(
    (onCleanup) => {
      if (!hasMatchMediaSupport(window)) {
        return;
      }

      (mediaQuery = window.matchMedia(toValue(query)))
        .addEventListener('change', matchMediaHandler);
      matches.set(!!mediaQuery?.matches);

      onCleanup(() => mediaQuery?.removeEventListener('change', matchMediaHandler));
    },
    { allowSignalWrites: true }
  );

  return matches.asReadonly();
}