import { AnimationTriggerMetadata, transition, trigger } from '@angular/animations';

/**
 * Animations/noops that prevent animations from running.
 */
export const disableAnimations: {
  readonly disableInitial: AnimationTriggerMetadata;
} = {
  disableInitial: trigger('disableInitial', [
    transition(':enter', [])
  ])
};